(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/clickable/assets/javascripts/clickable.js') >= 0) return;  svs.modules.push('/components/ui/clickable/assets/javascripts/clickable.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  var currentTarget = null;

  function determineEventType(targetElement) {
    if (
      svs.core.detect.os.android() &&
      targetElement.tagName.toLowerCase() === 'select'
    ) {
      return 'mousedown';
    }

    return 'click';
  }

  function sendClick(targetElement, event) {
    var clickEvent;

    if (document.activeElement && document.activeElement !== targetElement) {
      document.activeElement.blur();
    }

    clickEvent = document.createEvent('MouseEvents');
    clickEvent.initMouseEvent(
      determineEventType(targetElement),
      true,
      true,
      window,
      1,
      event.screenX,
      event.screenY,
      event.clientX,
      event.clientY,
      false,
      false,
      false,
      false,
      0,
      null
    );

    clickEvent.svsClickableEvent = true;

    clickEvent.forwardedTouchEvent = true;
    targetElement.dispatchEvent(clickEvent);
  }

  function onMouseDown(e) {
    var handleEvent = false;

    currentTarget = $(e.target);

    if (currentTarget.hasClass('js-clickable-ignore')) {
      return true;
    } else if (currentTarget.hasClass('js-clickable')) {
      handleEvent = true;
    } else {
      var parentIgnore = currentTarget.parents('.js-clickable-ignore');

      if (parentIgnore.length > 0) {
        return true;
      }
      var parent = currentTarget.parents('.js-clickable');

      if (parent.length > 0) {
        handleEvent = true;
        currentTarget = parent;
      }
    }

    if (handleEvent) {
      currentTarget.addClass('clickable-active');
    }
  }

  function onMouseUp() {
    if (currentTarget) {
      currentTarget.removeClass('clickable-active');
    }
  }

  function onClick(e) {
    if (e.svsClickableEvent) {
      return true;
    }

    var handleEvent = false;
    var currentTarget = $(e.target);

    if (currentTarget.hasClass('js-clickable-ignore')) {
      return true;
    } else if (currentTarget.hasClass('js-clickable')) {
      handleEvent = true;
    } else {
      var parentIgnore = currentTarget.parents('.js-clickable-ignore');

      if (parentIgnore.length > 0) {
        return true;
      }
      var parent = currentTarget.parents('.js-clickable');

      if (parent.length > 0) {
        handleEvent = true;
        currentTarget = parent;
      }
    }

    if (handleEvent) {
      currentTarget.addClass('clickable-active');

      setTimeout(function() {
        currentTarget.removeClass('clickable-active');
      }, 20);

      if (currentTarget.hasClass('js-clickable-delayed-click')) {
        currentTarget.one(
          'webkitTransitionEnd oTransitionEnd msTransitionEnd transitionend',
          function(e) {
            sendClick(currentTarget[0], e);

            currentTarget.trigger('clickable-end');
          }
        );

        e.stopPropagation();
        e.preventDefault();

        return false;
      }
      currentTarget.one(
        'webkitTransitionEnd oTransitionEnd msTransitionEnd transitionend',
        function() {
          currentTarget.trigger('clickable-end');
        }
      );

      return true;
    }
    return true;
  }

  if (svs.core.detect.formfactor.desktop()) {
    document.body.addEventListener('mousedown', onMouseDown, true);
    document.body.addEventListener('mouseup', onMouseUp, true);
  } else {
    document.body.addEventListener('click', onClick, true);
  }
})(svs);


 })(window);